@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inria+Serif&display=swap");
@import url("https://fonts.googleapis.com/css?family=Inconsolata");

@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  body,
  * {
    @apply font-inter;
  }
  :root {
    --primary: #ffb45f;
    --secondary: rgba(0, 0, 0, 0.5);
  }
    p {
        white-space: pre-line;
    }
  /* * {
    scrollbar-width: thin;
    scrollbar-color: var(--secondary) var(--primary);
    scroll-behavior: smooth;

  }
  

  *::-webkit-scrollbar {
    width: 15px;
  }
  
  *::-webkit-scrollbar-track {
    background: var(--primary);
    border-radius: 3px;
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: var(--secondary);
    border-radius: 3px;
    border: 3px solid var(--primary);
  } */
}

@layer components {
  .svg-wrapper {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
    width: 320px;
  }
  .shape {
    stroke-dasharray: 430 540;
    stroke-dashoffset: -357;
    strokeWidth: 8px;
    fill: transparent;
    stroke: #ffffff;
    border-bottom: 5px solid black;
    transition: strokeWidth 3s, stroke-dashoffset 3s, stroke-dasharray 3s;
  }
  .text {
    font-size: 22px;
    line-height: 32px;
    letter-spacing: 8px;
    color: #fff;
    top: -48px;
    position: relative;
    pointer-events: none;
  }
  /* .shape:hover {
    strokeWidth: 4px;
    stroke-dashoffset: 0;
    stroke-dasharray: 760;
  } */
  .shape.active {
    strokeWidth: 4px;
    stroke-dashoffset: 0;
    stroke-dasharray: 760;
  }
  .centerBox {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 600px */
  }

  .categoryWrapper {
    height: 150px;
    width: 300px;
    background: url("http://ohlookawebsite.com/bathroomtestfull.jpg") no-repeat
      center center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .categoryWrapper:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#a29ca9+0,95909b+99 */
    background: #a29ca9; /* Old browsers */
    background: -moz-linear-gradient(
      -45deg,
      #a29ca9 0%,
      #95909b 99%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      -45deg,
      #a29ca9 0%,
      #95909b 99%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      135deg,
      #a29ca9 0%,
      #95909b 99%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a29ca9', endColorstr='#95909b',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    content: "";
    opacity: 0;
    -webkit-transition: opacity 0.9s ease 0s;
  }

  .categoryWrapper:hover:after {
    opacity: 0.4;
  }

  .categoryWrapper h1 {
    color: white;
    font-size: 50px;
    letter-spacing: 2px;
    -webkit-transition: all 0.25s ease 0s;
    position: relative;
    z-index: 10;
  }

  .categoryWrapper:hover h1 {
    transform: translateY(-10px);
  }

  .categoryWrapper button {
    position: absolute;
    transform: translatey(50px);
    -webkit-appearance: none;
    border: none;
    background: none;
    color: white;
    width: 250px;
    height: 35px;
    font-size: 20px;
    padding: 0;
    margin: 0;
    outline: none;
    z-index: 10;
  }

  .categoryWrapper button span {
    display: block;
    position: relative;
    line-height: 1;
    height: 35px;
    cursor: pointer;
  }

  .categoryWrapper button > span:after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 20px;
    height: 0;

    border: 1px solid white;
    border-left: none;
    border-bottom: none;

    transition: height 0.15s ease-out, width 0.15s ease-out 0.15s;
  }

  .categoryWrapper:hover button > span:after {
    width: calc(50% - 1px);
    height: calc(100% - 2px);
    transition: width 0.15s ease-out, height 0.15s ease-out 0.15s;
  }

  .categoryWrapper button > span:before {
    content: "";
    position: absolute;
    top: 0;
    right: 50%;
    width: 20px;
    height: 0;

    border: 1px solid white;
    border-right: none;
    border-bottom: none;

    transition: height 0.15s ease-out, width 0.15s ease-out 0.15s;
  }

  .categoryWrapper:hover button > span:before {
    width: calc(50% - 1px);
    height: calc(100% - 2px);
    transition: width 0.15s ease-out, height 0.15s ease-out 0.15s;
  }

  .categoryWrapper button > span > span:before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0%;
    width: 1px;
    height: 1px;
    opacity: 0;
  }

  .categoryWrapper:hover button > span > span:before {
    opacity: 1;
    border-bottom: 1px solid white;
    width: calc(50%);
    height: 1px;
    transition: opacity 0s ease-out 0.29s, width 0.15s ease-out 0.3s;
  }

  .categoryWrapper button > span > span:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0%;
    width: 1px;
    height: 1px;
    opacity: 0;
  }

  .categoryWrapper:hover button > span > span:after {
    opacity: 1;
    border-bottom: 1px solid white;
    width: calc(50%);
    height: 1px;
    transition: opacity 0s ease-out 0.29s, width 0.15s ease-out 0.3s;
  }

  .categoryWrapper button > span > span > span {
    transition: color 0.15s ease-out 0.3s;
    color: transparent;
  }

  .categoryWrapper:hover button > span > span > span {
    color: white;
  }

  .categoryWrapper button > span > span > span:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: #1f2e4d;
    content: attr(data-attr-span);
    width: 0%;
    height: 100%;
    background: white;
    white-space: nowrap;
    text-align: center;
    margin: auto;
    overflow: hidden;
    display: flex;
    justify-content: center;
    transition: width 0.2s;
  }

  .categoryWrapper button:hover > span > span > span:after {
    width: 100%;
  }

  .loadingPersent {
    @apply font-inter lg:text-[50px] text-2xl;
    font-style: normal;
    font-weight: 400;

    line-height: 39px;
    /* identical to box height */

    background: linear-gradient(0deg, #fff 100%, rgba(255, 255, 255, 4) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-shadow: 0px 4px 4px #000000;
  }
  .swiper-button-prev {
    @apply !bg-mainColor absolute top-1/2 right-5 w-12 aspect-square flex justify-center items-center  rounded-full;
    z-index: 300;
  }
  .swiper-button-next {
    @apply !bg-mainColor absolute top-1/2 left-5 w-12 aspect-square flex justify-center items-center rounded-full;

    z-index: 300;
  }
  .swiper-button-next_aboutUs {
    @apply !bg-white absolute top-1/2 -left-1 w-12 h-20 aspect-square flex justify-center items-center rounded-full;
    border-radius: 12rem 0 0 12rem;
  }
  .imageShadow {
    filter: drop-shadow(-8px 8px 23px rgba(0, 0, 0, 0.51));
  }
}
